.links-library {
    color: antiquewhite;
    -webkit-text-stroke: 1px #222034;
    text-decoration: underline;
    text-decoration-color: #222034;
}
img {
    height: auto;
  }
.main-content-library {
    background-color: #37946e;
    background-image: url("../images/plainbg.png");
    padding-top: 24px;
}

.library-header-title {
    font-size: 36px;
    width: 100%;
    text-align: center;
}

.main-library {
    text-align: left;
    height: 100%;
    background-color: #99e550;
    font-family: 'Bungee', sans-serif;
    color: antiquewhite;
    -webkit-text-stroke: 1px #222034;
    font-size: 20px;
}

.main-break-library {
    background-color: #99e550;
    background-image: url("../images/TiledBreak.png");
    background-position: bottom;
    background-repeat: repeat-x;
    height: 60px;
    text-align: center;
}

.menu-library {
    text-align: left;
    color: antiquewhite;
    -webkit-text-stroke: 0px #222034;
    padding-left: 24px;
}

.ant-anchor-link-title {
    color: antiquewhite;
    -webkit-text-stroke: 1px #222034;
    font-size: 20px;
    padding-left: 12px;
}

.ant-anchor-link {
    color: antiquewhite;
    -webkit-text-stroke: 1px #222034;
    font-size: 20px;
    padding-left: 12px;
}

.ant-anchor {
    color: antiquewhite;
    -webkit-text-stroke: 1px #222034;
    font-size: 20px;
    padding-left: 12px;
}

.body-text-library {
    color: antiquewhite;
    border-radius: 16px;
    text-align: justify;
    margin-right: 24px;
    padding-right: 24px;
    padding-bottom: 40px;
    padding-left: 24px;
    background-color: rgba(0, 0, 0, 0.25);
}

.body-text-index {
    color: antiquewhite;
    border-radius: 16px;
    text-align: center;
    margin-right: 24px;
    padding-right: 24px;
    padding-bottom: 40px;
    padding-left: 24px;
}

.hero-library {
    -webkit-text-stroke: 2px #222034;
    border-radius: 10px;
    font-size: 40px;
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    color: antiquewhite;
}

.hero-library-sm {
    -webkit-text-stroke: 2px #222034;
    border-radius: 10px;
    font-size: 24px;
    text-align: center;
    padding-left: 140px;
    padding-right: 140px;
    color: antiquewhite;
}

@media only screen and (max-width: 779px) {
    .main-library {
        background-color: #99e550;
        font-size: 16px;
    }
    .main-row.ant-row {
        display: block;
    }
    .main-content-library {
        padding: 4px;
    }

    .body-text-library {
        width: 100%;
        text-align: center;
    }

    .hero-library {
        font-size: 24px;
        padding-left: 4px;
        padding-right: 4px;
    }
    .hero-library-sm {
        font-size: 24px;
        padding-left: 4px;
        padding-right: 4px;
    }

    .menu-col {
        display: none;
    }

    .ant-col-18 {
        max-width: 100%;
    }

    .main-content-library.ant-col {
        max-width: 100%;
    }



    .body-text-index {
       display: none;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}