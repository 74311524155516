body {
  height: 100%;
  min-height: 100%;
  background-color: red;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  background-color: red;

}

.links {
  color: antiquewhite;
  -webkit-text-stroke: 1px #222034;
  text-decoration: underline;
  text-decoration-color: #222034;
}

.main {
  text-align: center;
  height: 100%;
  background-color: #99e550;
  font-family: 'Bungee', sans-serif;
  color: #222034;
  -webkit-text-stroke: 1px #222034;
  font-size: 20px;
}

.main-break {
  background-color: #99e550;
  background-image: url("./images/TiledBreak.png");
  background-position: bottom;
  background-repeat: repeat-x;
  height: 100px;
  text-align: center;
}

.body-text {
  color: antiquewhite;
  border-radius: 6px;
  text-align: justify;
  float: right;
}

.body-text-flipped {
  color: antiquewhite;
  border-radius: 6px;
  text-align: justify;
  float: left;
}

.body-text-footer {
  color: antiquewhite;
  border-radius: 6px;
  text-align: center;
  padding-left: 160px;
  padding-right: 160px;
}

.body-image {
  color: antiquewhite;
  /*background-color: rgba(255, 255, 255, 0.5);*/
  border-radius: 6px;
  padding: 4px;
  float: left;
}

.body-image-flipped {
  color: antiquewhite;
  /*background-color: rgba(255, 255, 255, 0.5);*/
  border-radius: 6px;
  padding: 4px;
  float: right;
}

.body-wrapper {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 12px;
  max-width: 700px;
  position: relative;
}

.hero-body-wrapper {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 12px;
  color: antiquewhite;
  border-radius: 6px;
  text-align: justify;
}

.vine {
  padding: 16px;
  max-width: 396px;
  text-align: center;
}

.main-content {
  background-color: #37946e;
  background-image: url("./images/plainbg.png");
}

.mint-button.ant-btn {
  font-family: 'Bungee', cursive;
  background-color: antiquewhite;
  border-radius: 7px;
  border-color: #222034;
  font-size: 24px;
  text-align: center;
  height: 48px;
  width: 140px;
  margin: 16px;
}

.hero {
  background-color: "rgba(255, 255, 255, 0.5)";
  -webkit-text-stroke: 2px #222034;
  border-radius: "10px";
  font-size: 40px;
  text-align: center;
  padding-left: 240px;
  padding-right: 240px;
  color: antiquewhite;
}

.hero-body {
  background-color: "rgba(255, 255, 255, 0.5)";
  -webkit-text-stroke: 2px #222034;
  border-radius: "10px";
  font-size: 32px;
  text-align: center;
  color: antiquewhite;
  
  width: 50%;
}

.hero.ant-col {
  text-align: center;
}

img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}

.footer {
  font-family: 'Ranchers', cursive;
  color: antiquewhite;
  -webkit-text-stroke: 1px #222034;
  background-color: #847e87;
  padding: 4px;
  font-size: 24px;
  text-align: center;
}

.footer-button {
  padding-left: 14px;
  color: antiquewhite;
}

.ant-btn {
  background-color: red;
}

.center-div {
  float: right;
  position: relative;
  left: 0%;
  /* or right 50% */
  text-align: center;
}
.center-div-flipped {
  float: left;
  position: relative;
  right: 0%;
  /* or right 50% */
  text-align: center;
}

.custom-column {
  display: inline-block;
  width: 50%;
}
.hero-image-div {
  width: 25%;
}

.hero-image {
  max-height:80%;
  max-width:80%;
}

@media only screen and (max-width: 1000px) {
  .hero {
    font-size: 32px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .hero-body {
    width: 100%;
    padding: 20px;
  }

  .hero-image {
    display: none;
  }

  .body-wrapper {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.25);
    padding: 12px;
    max-width: 500px;
    position: relative;
  }

  .body-image {

    float: none;
  }

  .body-image-flipped {
    float: none;
  }

  .content-row {
    display: flex;
  }


  .center-div {
    float: right;
    position: relative;
    left: 0%;
    /* or right 50% */
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
  }
  .center-div-flipped {
    float: right;
    position: relative;
    left: 0%;
    /* or right 50% */
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
  }

  .custom-column {
    width: 100%;
    text-align: center;
  }

  .body-text {
    color: antiquewhite;
    border-radius: 6px;
    text-align: justify;
    max-width: none;
  }

  .body-text-flipped {
    color: antiquewhite;
    border-radius: 6px;
    text-align: justify;
    max-width: none;
  }
}

@media only screen and (max-width: 779px) {
  .main {
    background-color: #99e550;
    font-size: 16px;
  }

  .hero-body-wrapper{
    max-width: none;
  }

  .hero {
    font-size: 24px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .custom-column {
    width: 100%;
    text-align: center;
  }

  .body-text-footer {
    color: antiquewhite;
    border-radius: 6px;
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
  }

  .body-text {
    color: antiquewhite;
    border-radius: 6px;
    text-align: justify;
  }

  .body-text-flipped {
    color: antiquewhite;
    border-radius: 6px;
    text-align: justify;
  }

  .body-image {
    float: none;
  }

  .body-image-flipped {
    float: none;
  }

  .footer {
    font-family: 'Ranchers', cursive;
    color: antiquewhite;
    -webkit-text-stroke: 0px #222034;
    padding: 4px;
    font-size: 12px;
    text-align: center;
  }
}

@media only screen and (max-width: 1200px) {
  .body-image {
    text-align: center;
  }

  .body-image-flipped {
    text-align: center;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}