.dex-main {
    height: 400px;
    text-align: center;
}

.dex-table {
    background-image: url("../images/book.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
    display: flex;
    color:#222034;
    font-family: Arial, Helvetica, sans-serif;;
    -webkit-text-stroke: 0px #222034;
    font-size: 16px;
}