.header {
    background-color: #222034;
    color: white;
    background-image: url("../images/TiledHeader.png");
    background-position: bottom;
    background-repeat: repeat-x;
    height: 100px;
    text-align: center;
}

.header-title {
    font-family: 'Ranchers', cursive;
    -webkit-text-stroke: 2px #222034;
    font-size: 24px;
}

.header-logo {
    margin-top: 10px;
    width: 64px;
    height: 64px;
}

.header-buttons.ant-btn {
    font-family: 'Bungee', cursive;
    margin: 6px;
    background-color: rgba(203, 219, 252, 0.5);
    border-radius: 7px;
    border-color: #222034;
    font-size: 16px;
    text-align: center;
    margin-top: 4px;
}

.header-buttons {
    font-family: 'Bungee', cursive;
    padding: 8px;
    color: antiquewhite;
    font-size: 20px;
    text-align: center;
}

.social-header{ 
    font-family: 'Bungee', cursive;
    padding: 8px;
    color: antiquewhite;
    font-size: 20px;
    text-align: center;   
}

.title-link {
    font-family: 'Ranchers', cursive;
    -webkit-text-stroke: 2px #222034;
    font-size: 50px;
    color: antiquewhite;
    display: flex;
}

.title-link:hover {
    color: #222034;
    text-decoration: none;
}

.title-link:focus {
    color: #222034;
}

.title-link:active {
    color: #222034;
}

.title-link:visited {
    color: antiquewhite;
}
.header-buttons:active {
    color: #222034;
}
.header-buttons:hover {
    color: #222034;
    text-decoration: underline;
}
.header-buttons:focus {
    color: #222034;
}
.header-buttons:visited {
    color: antiquewhite;
}







.social-header:active {
    color: #222034;
}
.social-header:hover {
    color: antiquewhite;
    text-decoration: underline;
}

.social-header:focus {
    color: #222034;
}
.social-header:visited {
    color: antiquewhite;
}




.header {
    color: antiquewhite;
    background-color: #639bff;
    background-image: url("../images/TiledHeader.png");
    background-position: bottom;
    background-repeat: repeat-x;
    height: 120px;
    padding-left: 12px;
    padding-right: 12px;
    text-align: center;
}

.button-area {
    text-align: right;
    margin-top: 16px;
}

.header-title {
    font-family: 'Ranchers', cursive;
    -webkit-text-stroke: 2px #222034;
    font-size: 50px;
}

.bigButtons {
    display: contents;
}

.header-drawer.ant-drawer .ant-drawer-content {
    font-family: 'Bungee', cursive;
    margin: 6px;
    border-radius: 7px;
    background-color: #222034;
    border-color: #222034;
    color: #222034;
    font-size: 20px;
    text-align: center;
}

.littleButtons {
    display: none;
}

.header-title-text {

    padding-left: 12px;
    padding-top: 6px;
    display: block;
}

@media only screen and (max-width: 1000px) {
    .header-title-text {
        font-size: 34px;
    }
}

@media only screen and (max-width: 779px) {

    .bigButtons {
        display: none;
    }

    .littleButtons {
        display: contents;
    }

    .button-area {
        margin-top: 4px;
    }

    .header-title {
        display: none;
        font-size: 30px;
    }

    .header-title-mobile {
        display: contents;
        font-size: 30px;
    }

    .header-title-text {
        display: none;
    }

    .header {
        background-color: #306082;
    }
}